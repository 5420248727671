.App {
  text-align: center;
  padding-top: 30px;
  font-weight: bold;
  font-size: 30px;
}
.Faqs {
  padding: 20px 70px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
}
.m10{
  margin-top: 10px;
}
.m5{
  margin-top: 5px;
  margin-left: 15px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.homelinks {
  position: fixed;
  bottom: 110px;
  display: flex;
  justify-content: space-between;
}
.homelinks >a {
  color: #636363;
}
.sharelinks {
  position: fixed;
  bottom: 20px;
  display: flex;
  width: 100%;
  padding-left: 20px;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.react-chatbot-kit-chat-inner-container {
  height: 100vh;
}
.react-chatbot-kit-chat-message-container {
  height: 90%;
}
.react-chatbot-kit-chat-container {
  width: 450px;
  margin: auto;
}
.pay-now {
  background: #0bbf64;
  font-family: Arial, sans-serif;
  color: #fff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  margin-top: 10px;
}
.react-chatbot-kit-chat-input-container {
  display: none !important;
}
.react-chatbot-kit-chat-header {
  display: none !important;
}
.react-chatbot-kit-chat-message-container {
  padding-top: 25px;
}
.App__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 15px;
  text-align: center;
}
