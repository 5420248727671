.flights-selector-button {
  padding: 8px 15px;
  border: 1px solid #0bbf64;
  border-radius: 25px;
  color: #fff;
  font-size: 0.9rem;
  margin: 3px;
  background-color: #0bbf64;
  width: 100px;
}

.flight-selector-button-container {
  display: flex;
  justify-content: space-between;
}

.flight-selector-header {
  font-size: 1rem;
  font-weight: normal;
}
