.options {
  padding-bottom: 15px;
}

.options-header {
  font-size: 1rem;
  font-weight: normal;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
}

.option-item {
  padding: 8px 15px;
  border: 1px solid #0bbf64;
  border-radius: 25px;
  background-color: #0bbf64;
  color: #fff;
  font-size: 0.9rem;
  margin: 3px;
  cursor: pointer;
}
