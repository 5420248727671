.url-link {
  font-size: 0.9rem;
  padding: 10px;
  border-radius: 3px;
  background-color: rgb(59, 211, 99);
  display: flex;
  align-items: center;
  color: #fff;
}

.url-icon {
  fill: #fff;
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

.url-header {
  font-size: 0.9rem;
}
