.airport-selector-container {
  background-color: #0bbf64;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin: -5px 0px;
  font-size: 0.9rem;
  text-align: center;
}

.airport-selector-heading {
  font-size: 1rem;
  margin: 0 0 8px 0;
}

.airport-button-confirm {
  display: block;
  margin: 12px auto;
  background-color: #fff;
  padding: 8px;
  border-radius: 5px;
  font-weight: bold;
  color: #505e86;
  width: 120px;
  border: none;
}

.airport-selector {
  color: #505e86;
  font-weight: bold;
  margin-top: 5px;
  border: none;
}
